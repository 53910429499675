<template>
  <v-card
    title="card-title"
    elevation="1"
    class="none-overflow"
    v-ripple
    @click="goWordTable"
    ><v-card-title class="card-title">单词本</v-card-title>

    <v-card-subtitle>查看所学单词</v-card-subtitle>
    <div class="content">
      <div class="icon">
        <svg-icon
          :url="require('@/assets/svgimgs/undraw_learning_-2-q1h.svg')"
          width="4.5rem"
          height="3rem"
        ></svg-icon>
      </div>
    </div>
    <v-card-actions>
      <v-btn rounded small plain class="right-button btn-disabled"
        >管理单词
        <v-icon right> mdi-play </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";
import SvgIcon from "@/components/beauty/svgIcon";

export default {
  name: "wordBook",
  components: { SvgIcon },
  mixins: [buttonVibrate],
  data: () => ({}),
  methods: {
    goWordTable() {
      this.$router.push("/wordtable");
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 4.5rem;
  //margin-top: -0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #ffad68;
  .icon {
    width: 4.5rem;
  }
}
.card-title {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  color: #404040;
  margin-top: -0.5rem;
}
.right-button {
  position: absolute;
  right: 0.6rem;
  bottom: 0.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  color: #dc7841;
  font-size: 0.9rem;
  font-weight: 700;
  width: 6.5rem;
  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
